<template>
  <Container>
    <div class="articles-list">
      <div class="articles-list-head">
        <h2 no-margin class="articles-headline">
          {{ headline }}
        </h2>
        <InternalLink v-if="link.value" :to="link.linkUrl" size="mini">
          {{ link.value }}
        </InternalLink>
      </div>
      <div class="articles-list-grid">
        <ArticlesListItem
          v-for="item in nestedElements"
          :key="item.id"
          :element="item"
        />
      </div>
    </div>
  </Container>
</template>

<script>
  import ArticlesListItem from "./articles_list_item.vue"
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    components: { ArticlesListItem },
    mixins: [AlchemyElement],
    computed: {
      headline() {
        return this.getValue("headline")
      },
      nestedElements() {
        return this.element.nestedElements
      },
      link() {
        return this.getIngredient("link_text")
      },
    },
  }
</script>

<style lang="scss" scoped>

  .articles-list {
  }

  .articles-list-head {
    display: flex;
    align-items: baseline;
    gap: $base-spacing * 2;
  }

  .articles-list-grid {
    display: flex;
    flex-direction: column;
    gap: $gap;
  }
  .articles-headline {
    @include type('h4');

    margin: 0;
    margin-bottom: $gutter;
  }
</style>
